<template>
    <v-container>
    <RemoteCtrl></RemoteCtrl>
    </v-container>
</template>

<script>

import RemoteCtrl from '../components/RemoteCtrl'

export default {
    components: {
        RemoteCtrl,
    }
}
</script>
