<template>
    <v-container fluid class="pa-0">
        <!-- <v-sheet
            :rounded="true"
            color="warning" min-height="40" 
            class="mb-3 d-flex align-center justify-center headline">
             {{ getClient().length == 12 ? 'Connected' : '' }}
        </v-sheet> -->
        <v-card color="info">
            <v-card-title class="d-flex justify-start primary text-uppercase text-subtitle-2">
                Navigation
            </v-card-title>
            <v-card-text class="d-flex flex-column mt-2">
                <template
                    v-for="(com, i) in commands"
                    >
                    <v-row dense
                        :key="`row` + i"
                        class="d-flex justify-center align-center">
                        <v-col
                            :key="`col1` + i"
                            class="col-6 d-flex justify-center"
                        >
                            <v-btn
                                :key="`nav` + i"
                                color="primary"
                                block
                                :disabled="com.hidden"
                                @click="cmd('nav', com.trg)"
                            >
                                {{ com.text }}
                            </v-btn>
                        </v-col>
                        <v-spacer/>
                        <v-col
                            :key="`col2` + i"
                            class="col-6 d-flex justify-center"
                        >
                            <v-switch
                                color="primary"
                                label="Hide"
                                v-model="com.hidden"
                                v-if="i>0"
                                @click="cmd('tog', com.trg)"
                                :key="`hide` + i">
                            </v-switch>
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-title class="d-flex justify-start primary text-uppercase text-subtitle-2">
                Color Themes
            </v-card-title>
            <v-card-text>
            <v-select
                class="mt-2"
                color="info"
                :dense="true"
                :solo="true"
                v-model="color"
                :items="themes">

            </v-select>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
    data: () => ({
        commands: [
            {text: 'Home', trg: 'landing', 'hidden': false},
            {text: 'About', trg: 'about', 'hidden': false},
            {text: 'Interact', trg: 'interact', 'hidden': false},
            {text: 'Attaboy', trg: 'attaboy', 'hidden': false},
            {text: 'Experience', trg: 'experience', 'hidden': false},
            {text: 'Timeline', trg: 'timeline', 'hidden': false},
        ],
        themes: [
            {
                text: "Original Theme",
                value: 1
            },
            {
                text: "Mars Dust",
                value: 2
            },
            {
                text: "Clashing Purple",
                value: 3
            },
        ],
        interact: false,
        color: 1,
    }),

    computed: {
        hostserver() {
            // return location.host.search(':8081') ? 'http://fc-api.test' : 'https://api.freelancecoder.net'
            return 'https://api.freelancecoder.net'
        },
    },

    watch: {
        color(val) {
            this.cmd('thm', val)
        }
    },

    methods: {
        ...mapGetters(['getClient']),
        cmd(exec, target) {
            if(this.getClient() != '') {
                var cmd = exec == 'nav' ? 'getctrl'+ '/' + target 
                    : exec == 'thm' ? exec + '/' + target 
                    : exec == 'tog' ? exec + '/' + target : exec
                // console.log(this.hostserver + '/api/1/' + cmd + '/' + this.getClient())
                axios.get(this.hostserver + '/api/1/' + cmd + '/' + this.getClient())
                    .then(() => {
                        // console.log(cmd + ' Executed')
                    }
                    )
            }
        }
    },

    mounted() {
        if(this.getClient() == ''){
            this.$router.push('/')
        } else {
            this.cmd('conn', '')
        }
    }
}
</script>